.new-main-left {
  &:hover {
    .TgUsersListGroupButton {
      transform: translateY(0);
      opacity: 1;
      z-index: 4;
    }
  }
}

.TgUsersListGroupButton {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(5rem);
  opacity: 0;
  z-index: -1;

  transition:
 transform 0.25s cubic-bezier(0.34, 1.56, 0.64, 1),
    opacity 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);

  &[dir="rtl"] {
    right: auto;
    left: 1rem;
  }

  body.no-page-transitions & {
    transform: none !important;
    opacity: 0;

    transition: opacity 0.15s;

    &.revealed {
      opacity: 1;
      z-index: 4;
    }
  }

  &.revealed {
    transform: translateY(0);
    opacity: 1;
    z-index: 4;

    @media (max-width: 600px) {
      // Force rendering in the composite layer to fix the z-index rendering issue
      transform: translate3d(0, 0, 0.625rem);
      transform-style: preserve-3d;
    }
  }

  > .Button {
    .icon-new-chat-filled,
    .icon-close {
      position: absolute;
    }

    &:not(.active) {
      .icon-new-chat-filled {
        animation: grow-icon 0.4s ease-out;
      }

      .icon-close {
        animation: hide-icon 0.4s forwards ease-out;
      }
    }

    &.active {
      .icon-close {
        animation: grow-icon 0.4s ease-out;
      }

      .icon-new-chat-filled {
        animation: hide-icon 0.4s forwards ease-out;
      }
    }
  }
}
