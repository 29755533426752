.Loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.interactive {
    cursor: var(--custom-cursor, pointer);
  }

  .Spinner {
    --spinner-size: 2.75rem;
  }

  .loading-tips{
    margin-top: 10px;
  }
}
