.root {
  margin: 0.25rem 0;
  border-radius: 0.0625rem;
  background-color: var(--color-background-menu-separator);
}

.thin {
  height: 0.0625rem;
}

.thick {
  height: 0.375rem;
}
