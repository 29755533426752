.LastMessageMeta {
  margin-right: 0.1875rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;

  .MessageOutgoingStatus {
    color: var(--color-text-meta-colored);
    margin-right: 0.125rem;
    font-size: 1.125rem;

    body.is-ios & {
      margin-bottom: -0.125rem;
    }
  }

  .time {
    color: var(--color-text-meta);
  }
}
