.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  .left-arrow {
    transform: rotate(180deg);
  }

  .iconfont {
    font-size: 1rem !important;
  }

  .page-input {
    width: fit-content;
  }
  .page-size-select {
    height: 30px;
    // border-radius: var(--border-radius-default);
  }
}
