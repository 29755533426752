.SearchInput {
  position: relative;
  width: 100%;
  color: rgba(var(--color-text-secondary-rgb), 0.5);
  background-color: var(--color-chat-hover);
  border: 2px solid var(--color-chat-hover);
  border-radius: 1.375rem;
  transition: border-color 0.15s ease;
  display: flex;
  align-items: center;

  padding-inline-end: 0.1875rem;

  &.with-picker-item {
    display: flex;

    .icon-container-left {
      display: none;
    }

    input {
      padding-left: 0.5rem;
    }

    &[dir="rtl"] {
      input {
        padding-right: 0.5rem;
        padding-left: 0;
      }
    }
  }

  &.has-focus {
    border-color: var(--color-primary);
    caret-color: var(--color-primary);
    background-color: var(--color-background);

    .search-icon {
      color: var(--color-primary);
    }
  }

  input {
    height: 2.5rem;
    margin-bottom: 0;
    border: none !important;
    border-radius: 1.375rem;
    background-color: transparent !important;
    box-shadow: none !important;
    padding:
      calc(0.4375rem - var(--border-width)) calc(0.625rem - var(--border-width))
      calc(0.5rem - var(--border-width)) calc(0.75rem - var(--border-width));

    &::placeholder {
      color: var(--color-placeholders);
    }
  }

  .icon-container-left {
    width: 1.5rem;
    flex-shrink: 0;
    margin-inline-start: 0.75rem;
  }

  .icon-container-right {
    width: 2.5rem;
    flex-shrink: 0;
    margin-inline-start: 0.5rem;
  }

  .icon-container-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-icon, .back-icon {
    font-size: 1.5rem;
    line-height: 1;
  }

  .back-icon {
    color: var(--color-text-secondary);
  }

  .Loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2.5rem;
    width: 2.5rem;

    .Spinner {
      --spinner-size: 1.5rem;
    }
  }

  .Button {
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    input {
      height: 2.5rem;
      border-radius: 1.25rem;
      padding-left: calc(0.75rem - var(--border-width));
    }
  }

  &[dir="rtl"] {
    input {
      direction: rtl;
    }
  }
}
