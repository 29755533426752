@use "../../../styles/mixins";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid var(--color-borders);
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;

  overflow-y: auto;
  max-height: 20rem;

  :global(.input-group) {
    margin-bottom: 0;
    flex-grow: 1;
  }

  :global(.form-control) {
    height: 2rem;
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none !important;
  }
}

.pickerCategoryTitle {
  color: var(--color-text-secondary);
  padding-inline: 0.5rem;
  font-weight: 500;

  &:not(:first-child) {
    border-top: 1px solid var(--color-borders);
    padding-top: 0.75rem;
    margin-top: 0.375rem;
  }
}

.pickerList {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.tgUserPickerItem {
  margin-bottom: 0.5rem;
}

.pickImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.Avatar {
  --premium-gradient:
 linear-gradient(
    88.39deg,
    #6c93ff -2.56%,
    #976fff 51.27%,
    #df69d1 107.39%
  );
  --color-user: var(--color-primary);
  --radius: 50%;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: var(--radius);
  color: white;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  user-select: none;
  position: relative;

  > .inner {
    overflow: hidden;
    border-radius: var(--radius);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    background-image:
 linear-gradient(
      var(--color-white) -125%,
      var(--color-user)
    );
  }

  &__media {
    width: 100%;
    height: 100%;
  }

  &__icon {
    font-size: 1.25em;
  }

  &.has-unread-story::before {
    background-image:
 linear-gradient(
      215.87deg,
      var(--color-avatar-story-unread-from) -1.61%,
      var(--color-avatar-story-unread-to) 97.44%
    );
  }

  &.has-unread-story.close-friend::before {
    background-image:
 linear-gradient(
      215.87deg,
      var(--color-avatar-story-friend-unread-from) -1.61%,
      var(--color-avatar-story-friend-unread-to) 97.44%
    );
  }

  .poster {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forum {
    --radius: var(--border-radius-forum-avatar);
  }

  &.hidden-user {
    --color-user: var(--color-deleted-account);
  }

  &.premium-gradient-bg > .inner {
    background-image: var(--premium-gradient);
  }
}

.padded {
  padding: 0.5rem;
  scrollbar-gutter: stable;

  @include mixins.adapt-padding-to-scrollbar(0.5rem);

  @media (max-width: 600px) {
    padding-inline: 0;
  }
}

.padded-all-checked {
  padding: 0.5rem 0.6875rem 0.5rem 0.5rem;
}

.noResults {
  height: 100%;
  margin: 0;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-secondary);
}

.onlineStatus {
  color: var(--color-primary);
}

.folder-title-input-element {
  display: flex;
  align-items: center;
}

.checkbox-element {
  margin-right: 1rem;
  position: relative;
  z-index: 2;
}

.searchInputContainer {
  max-height: 6.625rem;
}

.add-disable {
  opacity: 0;
}

.all-checked {
  border-bottom: 1px solid var(--color-borders);
}
