.MessageOutgoingStatus {
  width: 1.1875rem;
  height: 1.1875rem;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
  font-size: 1.1875rem;

  .icon-message-succeeded {
    padding-left: 0.125rem;
  }

  .Transition {
    width: 100%;
    height: 100%;
  }

  .MessageOutgoingStatus--failed::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0.25rem;
    border-radius: 50%;
    background: white;
  }

  .icon-message-failed {
    background: none;
    color: var(--color-error);
    z-index: 1;
    position: relative;
  }
}
