.TgUsersList1 {
  height: 100%;
}

.hidden {
  display: none;
}

.list-spread {
  min-width: var(--left-column-tgUserList-hover-width) !important;
}

.Avatar {
  --premium-gradient:
 linear-gradient(
    88.39deg,
    #6c93ff -2.56%,
    #976fff 51.27%,
    #df69d1 107.39%
  );
  --color-user: var(--color-primary);
  --radius: 50%;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: var(--radius);
  color: white;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  user-select: none;
  position: relative;

  > .inner {
    overflow: hidden;
    border-radius: var(--radius);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    background-image:
 linear-gradient(
      var(--color-white) -125%,
      var(--color-user)
    );
  }

  &__media {
    width: 100%;
    height: 100%;
  }

  &__icon {
    font-size: 1.25em;
  }

  &.has-unread-story::before {
    background-image:
 linear-gradient(
      215.87deg,
      var(--color-avatar-story-unread-from) -1.61%,
      var(--color-avatar-story-unread-to) 97.44%
    );
  }

  &.has-unread-story.close-friend::before {
    background-image:
 linear-gradient(
      215.87deg,
      var(--color-avatar-story-friend-unread-from) -1.61%,
      var(--color-avatar-story-friend-unread-to) 97.44%
    );
  }

  .poster {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forum {
    --radius: var(--border-radius-forum-avatar);
  }

  &.hidden-user {
    --color-user: var(--color-deleted-account);
  }

  &.premium-gradient-bg > .inner {
    background-image: var(--premium-gradient);
  }
}

.info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 0.5rem;

  .info-header {
    display: flex;
    align-items: center;
  }

  .info-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .info-new-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: max-height 0.3s ease, height 0.3s ease;
    height: auto;
    max-height: 0rem;

    &.show-msg {
      max-height: 2rem;
    }

    .content {
      color: var(--color-text-secondary);
      font-size: 0.6875rem;
      line-height: 1rem;
      flex: 1;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: normal;
    }

    .mark-as-read-btn {
      width: 1rem;
      height: 1rem;
      padding: 0;
      z-index: 1;
      margin-left: 0.25rem;

      .mark-as-read {
        font-size: 1rem;
      }
    }
  }

  .role {
    font-size: 0.75rem;
    color: var(--color-primary);
    margin-left: 0.25rem;
  }

  .name,
  .identification {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    flex: 1;
    font-weight: 500;
  }

  .identification {
    color: var(--color-text-secondary);
    font-size: 0.6875rem;
    display: flex;
    align-items: center;
    .country-flag {
      font-size: 0.6875rem;
      margin-right: 0.3125rem;
      .emoji {
        width: 1.333333em;
        line-height: 0.6875rem;
        display: inline-block;
      }
    }
  }

  .pin-icon {
    font-size: 0.6875rem;
  }
}

.avatar-online {
  position: absolute;
  bottom: 0.0625rem;
  right: 0.0625rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  border: 2px solid var(--color-background);
  background-color: #0ac630;
  flex-shrink: 0;
  z-index: 1;

  opacity: 0.5;
  transform: scale(0);
  transition: opacity 200ms, transform 200ms;

  &.avatar-online-shown {
    opacity: 1;
    transform: scale(1);
  }
}

.selected {
  --background-color: var(--color-chat-active) !important;

  &:hover {
    --background-color: var(--color-chat-active) !important;
  }

  .ListItem-button {
    --background-color: var(--color-chat-active) !important;
    --color-text: var(--color-white);
    --color-text-meta-colored: var(--color-white);
    --color-text-meta: var(--color-white);
    --color-text-secondary: var(--color-white);
    --color-error: var(--color-white);
    --color-list-icon: var(--color-white);
    --color-chat-username: var(--color-white);

    &:focus-visible {
      filter: brightness(120%);
    }
  }

  .role {
    color: var(--color-white);
  }
}

.ChatBadge-transition {
  opacity: 1;
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);

  &:not(.open) {
    transform: scale(0);
    opacity: 0;
  }

  &:not(.shown) {
    display: none;
  }

  &.closing {
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  }
}

.ChatBadge-wrapper {
  display: flex;

  .ChatBadge {
    margin-inline-start: 0.5rem;
  }
}

.ChatBadge {
  min-width: 1.5rem;
  height: 1.5rem;
  background: var(--color-gray);
  border-radius: 0.75rem;
  padding: 0 0.4375rem;
  color: white;
  font-size: 0.875rem;
  line-height: 1.5625rem;
  font-weight: 500;
  text-align: center;
  flex-shrink: 0;
}

.Independent-badge {
  position: absolute;
  top: -0.4375rem;
  right: -0.75rem;
  right: 0;
  z-index: 2;
  background: var(--color-topic-red) !important;
}

.independent-header {
  position: sticky;
  top: 0;
  z-index: 4;

  .independent-item {
    border-bottom: 0.125rem solid var(--color-borders);
    background-color: var(--color-background) !important;
    cursor: pointer;
  }

  .search-item {
    background-color: var(--color-background) !important;

    & > .ListItem-button {
      padding: 0.5rem 0 !important;
      overflow: visible;
    }

    .animated-menu-icon {
      position: absolute;

      &,
      &::before,
      &::after {
        width: 1.125rem;
        height: 0.125rem;
        border-radius: 0.125rem;
        background-color: var(--color-text-secondary);
        transition: transform 0.25s;
        transform: rotate(0);
      }

      &.active,
      &.active::before,
      &.active::after {
        background-color: var(--color-primary); // 激活时的颜色
      }

      &::before,
      &::after {
        position: absolute;
        left: 0;
        content: "";
      }

      &::before {
        top: -0.3125rem;
      }

      &::after {
        top: 0.3125rem;
      }

      &.state-back {
        transform: rotate(180deg);

        &::before {
          transform: rotate(45deg) scaleX(0.75) translate(0.375rem, -0.1875rem);
        }

        &::after {
          transform: rotate(-45deg) scaleX(0.75) translate(0.375rem, 0.1875rem);
        }
      }

      &.no-animation {
        transition: none;

        &::before,
        &::after {
          transition: none;
        }
      }
    }
  }
}

.all-read {
  color: var(--color-success) !important;

  & > .icon {
    color: var(--color-success) !important;
  }
}

.tg-user-group {
  position: relative;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: all 0.3s ease;
  overflow-y: scroll;
  scrollbar-width: thin;

  .tg-user-box {
    .tg-user-group-title-list-item {
      background-color: var(--color-background);
      position: sticky;
      top: -1px;
      z-index: 3;

      .tg-user-group-title {
        font-size: 0.875rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .tg-user-group-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
        }
      }
    }

    .tg-user-group-list {
      height: 0;
      overflow-x: hidden;
      scrollbar-width: thin;
      overflow-anchor: none;
      /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
      transition: 0.3s all ease;

      &.expand-list {
        height: auto;
      }
    }
  }
}

.menus-item-mini-title {
  margin-left: 0.625rem;
  font-size: xx-small;
  color: #aaa6a6;
}

.menus-item-mini-lable {
  margin-left: 0.625rem;
  font-size: small;
}

.menus-item-radio {
  display: flex;
  justify-content: space-between;
}

.radio-theme {
  border: 2px solid var(--color-borders-input);
  border-radius: 50%;
  background-color: var(--color-background);
  opacity: 1;
  transition: border-color 0.1s ease, opacity 0.1s ease;
}

.auto-reply {
  margin-left: 5px;
  cursor: pointer;
  color: var(--color-chat-active);
}

.auto-reply:hover {
  color: var(--color-chat-active);
}

.qunfa {
  display: inline-flex;
 align-items: center;
 position: relative;
 margin: 0;
 justify-content: end;
 margin-left: auto;
}
