@font-face {
  font-family: "iconfont"; /* Project id 4675445 */
  src: url('iconfont.woff2?t=1730444282500') format('woff2'),
       url('iconfont.woff?t=1730444282500') format('woff'),
       url('iconfont.ttf?t=1730444282500') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wg-mass-texting:before {
  content: "\e645";
}

.icon-wg-arrow:before {
  content: "\e952";
}

.icon-wg-arrow-double:before {
  content: "\e953";
}

.icon-wg-tooltip:before {
  content: "\e677";
}

.icon-wg-sucaiku:before {
  content: "\e632";
}

.icon-wg-search:before {
  content: "\e69c";
}

.icon-ai-translation:before {
  content: "\e69b";
}

