.PickerSelectedItem {
  display: flex;
  align-items: center;
  background: var(--color-chat-hover);
  height: 2rem;
  min-width: 2rem;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-right: 1rem;
  border-radius: 1rem;
  cursor: var(--custom-cursor, pointer);
  position: relative;
  overflow: hidden;
  flex-shrink: 1;
  transition: background-color 0.15s ease;

  max-width: calc(50% - 0.5rem);

  &.minimized {
    padding-right: 0;
  }

  &:hover {
    background-color: var(--color-item-active);
  }

  &.closeable:hover {
    background-color: rgba(var(--color-error-rgb), 0.16);

    .item-remove {
      opacity: 1;
    }

    .Avatar,
    .item-icon {
      opacity: 0;
    }
  }

  &.search-date {
    .item-remove {
      background: var(--color-primary);
    }

    &:hover {
      background: var(--color-chat-hover);
    }

    .item-name {
      font-size: 0.875rem;
    }
  }

  &.fluid {
    max-width: unset;
  }

  .Avatar,
  .item-icon {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    flex-shrink: 0;
    transition: opacity 0.15s ease;

    .icon {
      font-size: 1rem;
    }
  }

  .item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    border-radius: 50%;
    background-color: var(--color-primary);
    color: white;

    .icon {
      font-size: 1.25rem;
      position: relative;
      top: -1px;
    }
  }

  .item-name {
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .emoji.emoji-small {
      width: 1.125rem;
      height: 1.125rem;
      background-size: 1.125rem;
      vertical-align: -2px;
    }
  }

  .pick-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .Avatar {
    --premium-gradient:
      linear-gradient(
88.39deg,
        #6c93ff -2.56%,
        #976fff 51.27%,
        #df69d1 107.39%
);
    --color-user: var(--color-primary);
    --radius: 50%;
    flex: none;
    align-items: center;
    justify-content: center;
    width: 3.375rem;
    height: 3.375rem;
    border-radius: var(--radius);
    color: white;
    font-weight: bold;
    display: flex;
    white-space: nowrap;
    user-select: none;
    position: relative;

    >.inner {
      overflow: hidden;
      border-radius: var(--radius);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 1;
      align-items: center;
      justify-content: center;
      background-image:
        linear-gradient(
var(--color-white) -125%,
          var(--color-user)
);
    }

    &__media {
      width: 100%;
      height: 100%;
    }

    &__icon {
      font-size: 1.25em;
    }

    &.has-unread-story::before {
      background-image:
        linear-gradient(
215.87deg,
          var(--color-avatar-story-unread-from) -1.61%,
          var(--color-avatar-story-unread-to) 97.44%
);
    }

    &.has-unread-story.close-friend::before {
      background-image:
        linear-gradient(
215.87deg,
          var(--color-avatar-story-friend-unread-from) -1.61%,
          var(--color-avatar-story-friend-unread-to) 97.44%
);
    }

    .poster {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &.forum {
      --radius: var(--border-radius-forum-avatar);
    }

    &.hidden-user {
      --color-user: var(--color-deleted-account);
    }

    &.premium-gradient-bg > .inner {
      background-image: var(--premium-gradient);
    }
  }

  .item-remove {
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-error);
    font-size: 1.5rem;
    color: white;
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &.square-avatar {
    border-start-start-radius: 0.625rem;
    border-end-start-radius: 0.625rem;
    --border-radius-forum-avatar: 0.625rem;

    &.minimized,
    .item-remove {
      border-radius: 0.625rem;
    }
  }

  &[dir="rtl"] {
    padding-left: 1rem;
    padding-right: 0;

    &.minimized {
      padding-right: 0;
      padding-left: 0;
    }

    .SearchInput & {
      left: auto;
      right: -0.125rem;
    }

    .item-name {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    .item-remove {
      left: auto;
      right: 0;
    }
  }
}
