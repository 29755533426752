.MassTextingModal {

  h4 {
    margin-top: 0.8125rem;
    margin-bottom: 0;

    &::after {
      content: ' :';
    }
  }

  .dialog-buttons {
    position: sticky;
    bottom: 0;
    background-color: var(--color-background);
    padding: 0 1rem 1rem;
  }

  .upload-file-button {
    margin-bottom: 0.5rem;
  }

  .autoModel {
    display: flex;
  }

  .renderContent {
    display: flex;
  }

  .renderTg {
    margin-bottom: 1.5rem;

    .renderTime {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .btns {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .btnGroup {
      display: flex;
      justify-content: space-between;
    }

    .addBtn {
      width: 45%;
    }

    .renderBr {
      height: 5px;
    }

    .pickImage {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .Avatar {
      --premium-gradient:
        linear-gradient(
88.39deg,
          #6c93ff -2.56%,
          #976fff 51.27%,
          #df69d1 107.39%
);
      --color-user: var(--color-primary);
      --radius: 50%;
      flex: none;
      align-items: center;
      justify-content: center;
      width: 3.375rem;
      height: 3.375rem;
      border-radius: var(--radius);
      color: white;
      font-weight: bold;
      display: flex;
      white-space: nowrap;
      user-select: none;
      position: relative;

      >.inner {
        overflow: hidden;
        border-radius: var(--radius);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1;
        align-items: center;
        justify-content: center;
        background-image:
          linear-gradient(
var(--color-white) -125%,
            var(--color-user)
);
      }

      &__media {
        width: 100%;
        height: 100%;
      }

      &__icon {
        font-size: 1.25em;
      }

      &.has-unread-story::before {
        background-image:
          linear-gradient(
215.87deg,
            var(--color-avatar-story-unread-from) -1.61%,
            var(--color-avatar-story-unread-to) 97.44%
);
      }

      &.has-unread-story.close-friend::before {
        background-image:
          linear-gradient(
215.87deg,
            var(--color-avatar-story-friend-unread-from) -1.61%,
            var(--color-avatar-story-friend-unread-to) 97.44%
);
      }

      .poster {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }

      &.forum {
        --radius: var(--border-radius-forum-avatar);
      }

      &.hidden-user {
        --color-user: var(--color-deleted-account);
      }

      &.premium-gradient-bg > .inner {
        background-image: var(--premium-gradient);
      }
    }

    .removeTgIcon {
      font-size: 1.5rem;
      color: var(--color-text-secondary);
      padding: 0.5rem;
      margin-left: auto;
    }
  }

  .renderDelete {
    width: 1%;
    background-color: var(--color-chat-active);
    margin-left: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 3px;
    overflow: hidden;
    cursor: pointer;
    // transition: width 0.1s ease-in-out;
    color: transparent;
    opacity: 0.6;
    transition: transform 0.4s ease-in-out, opacity 0.1s ease-in-out;
  }

  .renderDelete:hover {
    width: 5%;
    color: #ffffff;
    opacity: 1;
    transform: scaleX(1);
  }
}

.list-box {
  overflow-y: auto;
  max-height: 300px;
}

.script-item {
  display: flex;
  position: absolute;
  z-index: 10;
  max-width: 80%;

  .script-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .script-class-tag {
    margin-left: 1rem;
    background-color: var(--color-primary);
    padding: 2px 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    min-width: 40px;
    text-align: center;
  }
}

.contact-search-container {
  display: grid;
  margin: 0.5rem 0.5rem 0 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.25rem;
  .contact-search {
    margin: 0 !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
