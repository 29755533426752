.Switcher {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.inactive {
    pointer-events: none;
  }

  body.no-page-transitions &,
  &.no-animation {
    .widget,
    .widget::after {
      transition: none !important;
    }

    .widget:active:after {
      width: 1.125rem;
    }
  }

  input {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    z-index: var(--z-below);
    opacity: 0;
  }

  .widget {
    cursor: var(--custom-cursor, pointer);
    text-indent: -999px;
    width: 2.125rem;
    height: 0.875rem;
    background-color: var(--color-gray);
    display: inline-block;
    border-radius: 0.5rem;
    position: relative;
    transition: background-color 150ms;
  }

  .widget:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.125rem;
    height: 1.125rem;
    background-color: var(--color-background);
    border-radius: 0.75rem;
    transition: transform 200ms;
    border: 0.125rem solid var(--color-gray);
  }

  input:checked + .widget {
    background: var(--color-primary);
  }

  input:checked + .widget:after {
    transform: translateX(100%);
    border-color: var(--color-primary);
  }
}
